import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FooterComponent from '../FooterComponent/FooterComponent';


const CollectionFourComponent = () => { 
  const [zoomedImage, setZoomedImage] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);

  const collections = [
    { id: 1, image: 'boli1.jpg', comment: 'paper size: 30(height) x 21 cm' },
    { id: 2, image: 'boli2.jpg', comment: 'paper size: 30(height) x 21 cm' },
    // Agrega comentarios para cada imagen según sea necesario
  ];

  const handleImageClick = (imageSrc) => {
    setZoomedImage(imageSrc);
    setZoomLevel(1);
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
  };

  const handleWheel = (e) => {
    const newZoomLevel = zoomLevel + e.deltaY / 100;
    if (newZoomLevel >= 1) {
      setZoomLevel(newZoomLevel);
    }
  };

  return (
    <div className="page-margin">
      <div className="title-container">
        <h1 className="collection-title">Collection of Faces and Emotions - Frank Giop -</h1>
      </div>
      <div className="collectionContainer">
        {collections.map((collection) => (
          <div className="collectionItem" key={collection.id}>
            <div className="collectionImage">
              <img
                src={`/images/boligrafo/${collection.image}`}
                alt={`Collection ${collection.id}`}
                onClick={() => handleImageClick(`/images/boligrafo/${collection.image}`)}
              />
              <div className="commentary">{collection.comment}</div>
            </div>
          </div>
        ))}
      </div>
      {zoomedImage && (
        <>
          <div className="darkOverlay" onClick={handleCloseZoom}></div>
          <div
            className="zoomedImage"
            onWheel={handleWheel}
            style={{ overflow: 'auto' }}
          >
            <button className="closeButton" onClick={handleCloseZoom}></button>
            <img
              src={zoomedImage}
              alt="Zoomed Image"
              style={{ transform: `scale(${zoomLevel})` }}
            />
          </div>
        </>
      )}
      <FooterComponent />
    </div>
  );
};

CollectionFourComponent.propTypes = {};

CollectionFourComponent.defaultProps = {};

export default CollectionFourComponent;
